<template>
  <div class="firewall">
    <div class="container">
      <div class="title">{{ !state.codeSent ? "접근이 제한된 IP 주소입니다." : "아이피 등록하기" }}</div>
      <div class="subtitle" v-if="!state.codeSent">IP 주소 등록을 위해<br>휴대폰 인증을 해주세요.</div>

      <div class="radio-container" v-if="!state.codeSent">
        <div class="radio-option">
          <label class="m-0"><input type="radio" v-model="state.durationType" value="SHORT">단기간 유효 아이피로 등록(버스, 카페 등)</label>
        </div>
        <div class="radio-option">
          <label class="m-0"><input type="radio" v-model="state.durationType" value="LONG">장기간 유효 아이피로 등록(사무실, 자택 등)</label>
        </div>
      </div>

      <div class="btn-container" v-if="!state.codeSent">
        <input class="form-control border-focus-point" type="text" v-model="state.mobile" placeholder="휴대전화 번호를 입력하세요." maxlength="11">
        <button class="btn btn-point" type="button" @click="sendCode">인증번호 발송</button>
      </div>

      <div class="btn-container" v-if="state.codeSent">
        <input class="form-control border-focus-point" type="text" v-model="state.code" placeholder="인증번호 6자리를 입력하세요" maxlength="6">
        <button class="btn btn-point" type="button" @click="verifyCode">인증하기</button>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import http from "@/scripts/http";
import router from "@/scripts/router";
import store from "@/scripts/store";

export default defineComponent({
  setup() {
    const state = reactive({
      mobile: "",
      code: "",
      durationType: "SHORT",
      codeSent: false,
    });

    const sendCode = async () => {
      try {
        await http.post("/api/firewall/code", {
          mobile: state.mobile,
          durationType: state.durationType
        });
        state.codeSent = true;
      } catch (error) {
        console.error(error);
      }
    };

    const verifyCode = async () => {
      try {
        const response = await http.put("/api/firewall/code", {
          code: state.code
        });
        if (response.data) {
          if (!store.state.account.loggedIn) {
            await store.dispatch("goLoginPage");
          } else {
            await router.push(window.decodeURIComponent(router.app.$route.query.redirectUrl || "/new-admin"));
          }
        } else {
          alert("인증 실패");
        }
      } catch (error) {
        console.error(error);
      }
    };

    return {state, sendCode, verifyCode};
  }
});
</script>

<style lang="scss" scoped>
.firewall {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .container {
    width: 100%;
    max-width: $px400;
    text-align: center;

    .title {
      font-size: $px24;
      margin-bottom: $px10;
    }

    .subtitle {
      font-size: $px17;
      margin-bottom: $px20;
    }

    .radio-container {
      margin-bottom: $px10;
      display: flex;
      flex-direction: column;
      align-items: center;

      .radio-option {
        input[type="radio"] {
          margin-right: $px8;
        }

        text-align: left;
        margin-bottom: $px5;
        width: calc(100% - $px20);
      }
    }

    input[type="text"],
    button {
      padding: $px10;
      margin: $px5 0;
      width: calc(100% - $px20);
    }

    .btn-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}
</style>